import React from 'react';
import { Route } from 'react-router-dom';
import {
	RouterTransition,
	AppWrapper,
	AgeScreener,
	CanvasProvider,
	useCanvasController,
	Warnings
} from 'bat-components';
import { useSelector } from 'react-redux';
import { Interactive } from 'components/molecules';
import { Empty } from 'components/pages';

import style from './app.module.scss';

export default function Main() {
	const { warnings } = useSelector(state => ({
		warnings: state.content.getIn(['content', 'warnings'])?.toJS()
	}));

	return (
		<AppWrapper mainClassName={style.main}>
			<CanvasProvider>
				<RouterTransition>
					<Route path="/" exact render={() => <AgeScreenerWrapper />} />
					<Route path="/home" component={Empty} />
				</RouterTransition>

				<Interactive></Interactive>
				<Warnings content={warnings} />
			</CanvasProvider>
		</AppWrapper>
	);
}

function AgeScreenerWrapper() {
	const controller = useCanvasController();

	const onAgeScreenerMount = () => {
		if (controller) {
			controller.go_to_age_screener();
		}
	};

	return (
		<AgeScreener
			next="/home"
			onMount={onAgeScreenerMount}
			displayIntroCTA={process.env.REACT_APP_SCREEN === 'tv'}
		/>
	);
}
