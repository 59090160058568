import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Layout, useCanvasController } from 'bat-components';

import style from './empty.module.scss';

export function Empty() {
	const controller = useCanvasController();

	const { locale, product_override } = useSelector(state => ({
		locale: state.content.getIn(['content', 'market_details', 'country']).toLowerCase(),
		product_override: state.content.getIn(['content', 'market_details', 'product_override'])
	}));

	useEffect(() => {
		if (controller) {
			if (locale === 'za') {
				let deviceObj = { deviceId: 'epod', brandId: 'vuse' };
				controller.go_to_product(deviceObj);
			} else if (product_override) {
				let deviceObj = { deviceId: product_override, brandId: 'vype' };
				controller.go_to_product(deviceObj);
			} else {
				controller.go_to_menu();
			}
		}
	}, [controller, locale, product_override]);

	return <Layout className={style.layout}></Layout>;
}
