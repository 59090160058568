import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import { FlavourCanvas, useCanvasController } from 'bat-components';
import { FlavourWall, FlavourWall_za } from 'flavour_wall';

import 'flavour_wall/dist/style.css';

export function Interactive() {
	const controllerRef = useRef();
	useCanvasController(controllerRef.current);

	const { data, market_details, locale } = useSelector(state => ({
		data: state.content.getIn(['content', 'flavour_canvas']).toJS(),
		market_details: state.content.getIn(['content', 'market_details']).toJS(),
		locale: state.content.getIn(['content', 'market_details', 'country']).toLowerCase()
	}));

	useEffect(() => {
		controllerRef.current.registerAnalyticsEvent(({ event, data }) => {
			//Called by WebGL on user interaction
			//@event string - (eg: 'device_click', 'flavour_click')
			//@data string - (eg: 'epen3', 'tobacco')

			ReactGA.event({
				category: `Selected ${event}`, // required
				action: `${data}`, // required
				label: 'Event fired by WebGL' // optional
			});
		});
	}, []);

	return (
		<FlavourCanvas
			controller={locale === 'za' ? FlavourWall_za : FlavourWall}
			controllerRef={controllerRef}
			data={{ market_details, ...data }}
		></FlavourCanvas>
	);
}
